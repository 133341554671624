<template>
    <modal ref="modalCrearLiquidador" titulo="Liquidador" tamano="modal-lg" no-aceptar no-cancelar>
        <div class="row mx-0 px-4">
            <div class="col-auto px-0">
                <img :src="liquidador.usuario_foto" class="obj-cover br-4" width="162" height="162" />
            </div>
            <div class="col-8 px-3">
                <div class="row mx-0 mb-3">
                    <div class="col px-1">
                        <p class="text-general f-18 f-500">
                            {{ liquidador.usuario }}
                        </p>
                        <p class="text-gris2 f-18">
                            {{ liquidador.cargo }}
                        </p>
                    </div>
                    <div class="col-auto px-2 text-gris2 f-18 f-500 pt-1">
                        {{ liquidador.estado === 1 ? 'Activo' : 'Inactivo' }}
                    </div>
                    <el-popover
                    ref="optLiquidaciones"
                    placement="bottom"
                    trigger="click"
                    width="200"
                    popper-class="p-0 br-none"
                    >
                        <div v-if="$can('boton_nomina_gestionar_liquidador')" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="irEditar">
                            Editar
                        </div>
                        <div v-if="$can('boton_nomina_eliminar_liquidador')" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="irEliminar">
                            Eliminar
                        </div>
                        <div v-if="liquidador.estado == 0 && $can('boton_nomina_gestionar_liquidador')" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="putEstado(1, 'activo');$refs.optLiquidaciones.doToggle();">
                            Activar
                        </div>
                        <div v-if="liquidador.estado == 1 && $can('boton_nomina_gestionar_liquidador')" class="row mx-2 my-2 item-option align-items-center px-3 cr-pointer" @click="putEstado(0, 'inactivo');$refs.optLiquidaciones.doToggle();">
                            Inactivar
                        </div>
                        <div slot="reference" class="btn-action border">
                            <i class="icon-dots-vertical f-22" />
                        </div>
                    </el-popover>
                </div>
                <div class="row mx-0">
                    <i class="icon-mail f-17 text-general" />
                    <div class="col px-2">
                        <p class="text-general f-18">{{ liquidador.correo }}</p>
                        <small class="text-gris2 f-14">
                            Correo de acceso
                        </small>
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <div v-if="liquidador.liquidador === 1" class="row mx-0">
                    <div class="pill-tipo">
                        <i class="f-20 text-white icon-account-check" />
                        <span class="text-white f-16">Tipo liquidador</span>
                    </div>
                </div>
                <div v-if="liquidador.contacto === 1" class="row mx-0 mt-3">
                    <div class="pill-contacto">
                        <i class="f-20 text-white icon-support" />
                        <span class="text-white f-16">Tipo de contacto</span>
                    </div>
                    <div class="col pt-2">
                        <p class="text-general">
                            <i class="icon-phone f-18" />
                            <span>{{ liquidador.contacto_telefono }}</span>
                        </p>
                        <p class="text-general mt-1">
                            <i class="icon-mail f-18" />
                            {{ liquidador.	contacto_correo }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Service from '~/services/nomina/liquidadores'
export default {
    data(){
        return {
            liquidador: {},
        }
    },
    mounted(){
        console.log(this.$refs.optLiquidaciones);
    },
    methods: {
        async putEstado(estado, bandera){
            try {
                const form = {
                    estado: estado
                };
                const {data} = await Service.putEstadoLiquidador(this.liquidador.id, form);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('actualizarEstado',this.liquidador.id, estado, bandera);
                this.liquidador.estado = estado;
            } catch(e){
                this.error_catch(e)
            }
        },
        irEditar(){
            this.$emit('irEditar',this.liquidador);
            this.$refs.modalCrearLiquidador.toggle();
            this.$refs.optLiquidaciones.doToggle();
        },
        irEliminar(){
            this.$emit('irEliminar',this.liquidador.id,this.liquidador.estado);
            this.$refs.modalCrearLiquidador.toggle();
            this.$refs.optLiquidaciones.doToggle();
        },
        toggle(data){
            this.liquidador = data;
            this.$refs.modalCrearLiquidador.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.pill-tipo{
    width: 179px;
    background-color: #29D884;
    color: #FFFFFFFF;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 39px;
}

.pill-contacto{
    width: 179px;
    background-color: #000000;
    color: #FFFFFFFF;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 39px;
}

.item-option{
    height: 32px;
    border-radius: 4px;
    color: 13px;
    &:hover{
        background-color: #F6F9FB;
    }
}
</style>
